import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import NotificacaoService from "../../service/NotificacaoService";
import {showToast} from "../ToastManager";

const notificaçãoService = new NotificacaoService();

const ListarBloqParceladas = () => {
    const [notificacoes, setNotificacoes] = useState([]);
    const [parceladasNotificacoes, setParceladasNotificacoes] = useState([]);
    const [digitalNotificacoes, setDigitalNotificacoes] = useState([]);
    const [loadingParceladas, setLoadingParceladas] = useState(false);
    const [loadingDigital, setLoadingDigital] = useState(false);

    useEffect(() => {
        initNotificacoes();
    }, []);

    const initNotificacoes = () => {
        setLoadingParceladas(true);
        setLoadingDigital(true);
        notificaçãoService.getNotificacoesBloqueadas().then(data => {
            const todasNotificacoes = data.data;

            // Filtra as notificações parceladas (13 a 21 parcelas)
            const parceladas = todasNotificacoes.filter(notificacao => notificacao.parcels >= 13 && notificacao.parcels <= 21);

            setParceladasNotificacoes(parceladas);
            setLoadingParceladas(false);
            setLoadingDigital(false);
        });
    }

    const precoBody = (rowData) => {
        return <span>{formatCurrency(rowData.valor / 100)}</span>;
    }

    const formatCurrency = (value) => {
        if (value != null) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        } else {
            return "";
        }
    }
    const cnpjBody = (rowData) => {
        return <span>{formatCnpj(rowData.cnpj)}</span>
    }

    const formatCnpj = (value) => {
        if (value != null) {
            return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
        } else {
            return "";
        }
    }
    const bloqueiaNotificacao = (notificacao) => {
        setLoadingParceladas(true);
        notificaçãoService.desbloqueiaNotificacao(notificacao.id).then(() => {
            showToast({severity:'success', summary:'Sucesso', detail:'Notificação desbloqueada com sucesso'});
            setLoadingParceladas(false);
            initNotificacoes(); // Atualiza as notificações após desbloquear
        });
    }

    const opcoesBody = (rowData) => {
        return (
            <Button loading={loadingParceladas} icon="pi pi-check-square" onClick={() => { bloqueiaNotificacao(rowData) }} style={{ backgroundColor: 'green', borderColor: 'green' }} className="p-button-rounded mr-2 mb-2" />
        );
    }

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Notificações Parceladas Bloqueadas (13 a 21 Parcelas)</h5>
                    <DataTable value={parceladasNotificacoes} paginator className="p-datatable-gridlines" showGridlines rows={10}
                               dataKey="id" loading={loadingParceladas} responsiveLayout="scroll"
                               emptyMessage="Nenhuma notificação parcelada bloqueada cadastrada.">
                        <Column sortable field="alerta.nome" header="Nome" filter filterPlaceholder="Procurar por nome" style={{ minWidth: '12rem' }} />
                        <Column sortable field="dataFormatada"   header="Data da notificação" filter filterPlaceholder="Procurar por data" style={{ minWidth: '12rem' }} />
                        <Column sortable field="dataPagamentoFormatada"   header="Data do pagamento" filter filterPlaceholder="Procurar por data" style={{ minWidth: '12rem' }} />
                        <Column sortable field="valor"   body={precoBody} header="Valor" filter filterPlaceholder="Procurar por valor" style={{ minWidth: '12rem' }} />
                        <Column sortable field="acquirerNSU"  header="Acquirer NSU" filter filterPlaceholder="Procurar por nsu" style={{ minWidth: '12rem' }} />
                        <Column sortable field="cnpj" body={cnpjBody} header="CNPJ" filter filterPlaceholder="Procurar por cnpj" style={{ minWidth: '12rem' }} />
                        <Column sortable field="merchant"  header="Merchant" filter filterPlaceholder="Procurar por Merchant" style={{ minWidth: '12rem' }} />
                        <Column sortable field="descricao"  header="Descrição" filter filterPlaceholder="Procurar por Descrição" style={{ minWidth: '12rem' }} />
                        <Column sortable field="cardNumber"  header="Numero do cartão" filter filterPlaceholder="Procurar por numero do cartão" style={{ minWidth: '12rem' }} />
                        <Column sortable field="cardHolder"  header="Card holder" filter filterPlaceholder="Procurar por card holder" style={{ minWidth: '12rem' }} />
                        <Column sortable field="parcels"  header="Parcelas" filter filterPlaceholder="Procurar por parcelas" style={{ minWidth: '12rem' }} />
                        <Column sortable field="merchantCategoryCode"  header="Merchant category code" filter filterPlaceholder="Procurar por merchant code" style={{ minWidth: '12rem' }} />
                        <Column sortable field="statusText"  header="Status" filter filterPlaceholder="Procurar por status" style={{ minWidth: '12rem' }} />
                        <Column body={opcoesBody} header="Ações" style={{ minWidth: '12rem' }} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ListarBloqParceladas, comparisonFn);
